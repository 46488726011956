<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div v-if="!loading" class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          แก้ไขเขต
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row d-flex justify-content-center mb-4">
          <label for="secondaryProductCode" class="form-label col-sm-10"
            >รหัสเขต</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.code"
              type="text"
              class="form-control"
              name="secondaryProductCode"
              id="secondaryProductCode"
            />
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <label
            for="secondaryProductName"
            class="required form-label col-sm-10"
            >ชื่อเขต</label
          >
          <div class="col-sm-10">
            <input
              v-model="form.name"
              type="text"
              class="form-control"
              name="secondaryProductName"
              id="secondaryProductName"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <!-- <div class="row d-flex justify-content-center">
          <label for="selectMainProduct" class="form-label col-sm-10"
            >เลือกกลุ่มสินค้าหลัก</label
          >
          <div class="col-sm-10">
            <select
              class="form-select"
              v-model="form.productGroupId"
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
            >
              <option v-for="item in typeProduct" :key="item" :value="item.id">
                {{ item.code }} | {{ item.name }}
              </option>
            </select>
          </div>
        </div> -->
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loadingUpdate"
            >
              <div
                v-if="loadingUpdate"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loader from "../../components/loaderUpdate.vue";
import customerAPI from "@/api/customer/";

export default {
  setup() {
    document.title = "CHOMTHANA | แก้ไขประเภทสินค้า";
  },
  components: {
    Loader,
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    isSubmit: false,

    form: {
      code: "",
      name: "",
      productGroupId: "",
    },

    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,

    mainProduct: [],
  }),

  async created() {
    // await this.getMainProduct();
    await this.getOne();
  },

  methods: {
    async getMainProduct() {
      let responseData = [];
      try {
        responseData = await customerAPI.zone.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.mainProduct = responseData.data;
        // this.form.productGroupId = responseData.data[0].id;
      }
    },
    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await customerAPI.zone.getOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.form = {
          ...getOneResponse.data,
          // productGroupId: getOneResponse.data.mainProductGroupId,
        };
        this.loading = false;
      }
    },
    async submit() {
      let updateResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      this.form = {
        ...this.form,
        mainProductGroupId: this.form.productGroupId,
        // status: this.form.status === "2" ? "0" : this.form.status,
        companyId: parseInt(localStorage.getItem("companyId")),
      };
      if (this.isValid) {
        this.loadingUpdate = true;
        updateResponse = await customerAPI.zone.update(
          this.$route.query.id,
          this.form
        );
        if (updateResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: `แก้ไขสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/CUSTOMER/zone/index",
              query: {},
            });
          });
          this.loadingUpdate = false;
        } else {
          Swal.fire({
            icon: "error",
            title: "แก้ไขไม่สำเร็จ !",
            showConfirmButton: false,
            timer: 1500,
          });
          this.loadingUpdate = false;
        }
      }
    },

    checkFormIsEmpty() {
      // !this.form.code
      //   ? (this.isValid = false)
      //   :
      !this.form.name
        ? (this.isValid = false)
        // : !this.form.productGroupId
        // ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    cancel() {
      this.$router.push({
        path: "/CUSTOMER/zone/index",
        query: {},
      });
    },
  },
};
</script>
